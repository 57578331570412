import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en' // lang i18n

import router from './router'
import store from './store'

Vue.use(ElementUI, { locale })
// 设置rem以及相关配置
import remAndPhoneConfig from "./config/remConfig";
remAndPhoneConfig();
window.onresize = function() {
  remAndPhoneConfig();
};

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
}).$mount('#app')
