import { setToken, getToken, removeToken } from "@utils/auth"
//  存储数据
const state = {
  token: getToken(), //从缓存中读取初始值
}
//  修改数据
const mutations = {

}
// 异步操作  
const actions = {

}

export default {
  namespaced: true,// 命名空间
  state,
  mutations,
  actions
}